import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";

export default function BlogPost({ data, pageContext }): JSX.Element {
  const datePublished = new Date(data.post.frontmatter.date);
  const dateTimeOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  return (
    <Layout pageContext={pageContext}>
      {/* <Helmet meta={[
        {
          'name': 'description',
          'content': data.post.frontmatter.description
        }
      ]}/> */}
      <article>
        <header className="mb-4 mw-80">
          <h1 className="post-title mb-3">{data.post.frontmatter.title}</h1>
          <span className="date-published">
            Published on{" "}
            <time dateTime={data.post.frontmatter.date}>
              {datePublished.toLocaleDateString(undefined, dateTimeOptions)}
            </time>
          </span>
        </header>
        <section>
          <MDXRenderer>{data.post.body}</MDXRenderer>
        </section>
      </article>
    </Layout>
  );
}

export const query = graphql`
  fragment NextAndPrev on Mdx {
    frontmatter {
      title
    }
    slug
  }

  query blogPost($id: String!, $nextPageId: String, $previousPageId: String) {
    post: mdx(id: { eq: $id }) {
      frontmatter {
        title
        description
        date
        tags
      }
      body
    }
    next: mdx(id: { eq: $nextPageId }) {
      ...NextAndPrev
    }
    prev: mdx(id: { eq: $previousPageId }) {
      ...NextAndPrev
    }
  }
`;
